import { SelectDos } from "../plugins/select2";
import {
    getData,
    CSRF,
    getJson,
    b64Uri,
    setSelect2Ajax,
    defaultSelect2,
} from "../helpers";

export const dataAccounting = {
    notIn: [],
    concat: [],
    self: [],
};

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectAccountingPlan extends HTMLSelectElement {
    dataS = { concat: [], notIn: [] };

    async connectedCallback() {
        let self = this;
        let isParent = this.getAttribute("data-isParent");
        let managementFilter = this.hasAttribute("data-not-filter-management");
        let accountGroup = this.getAttribute("data-account-group");
        let configSelect = {
            ajax: {
                url: getData("accountingplansUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    // Simular scroll infinito
                    let page = param.page || 1;
                    // filtro
                    if (page == 1) {
                        switch (getData(self, "data-filter")) {
                            case "shared": {
                                if (dataAccounting.notIn.length) {
                                    self.dataS.notIn = dataAccounting.notIn;
                                }
                                break;
                            }
                            case "self": {
                                if (dataAccounting.self[self.name]?.length) {
                                    self.dataS.notIn =
                                        dataAccounting.self[self.name];
                                }
                                break;
                            }
                        }
                    }

                    return {
                        _token: CSRF,
                        search: param.term,
                        isParent: isParent,
                        managementFilter: managementFilter,
                        accountGroup: accountGroup,
                        dataAccounting: JSON.stringify(self.dataS),
                        page: page,
                    };
                },
                processResults: function (response, params) {
                    // Simular scroll infinito
                    params.page = params.page || 1;
                    let more =
                        params.page * response.limit < response.total_count;
                    return {
                        results: response.items,
                        pagination: {
                            more: more,
                        },
                    };
                },
            },
        };

        const SD = new SelectDos(configSelect);
        const S = await SD.selectDos(this);

        S[0]?.addEventListener("aj:select2", (e) => {
            const Data = e.detail.data;

            this.filter(Data);
        });

        S[0]?.addEventListener("aj:change", (e) => {
            const Data = e.detail.data;

            this.filter(Data);
        });

        if (getData(this, "data-default")) {
            let Data = getJson(b64Uri(getData(this, "data-default"), "decode"));
            if (Data?.id) {
                setSelect2Ajax(
                    this,
                    Data.id,
                    Data.number + " - " + Data.name,
                    Data
                );
            }
        }
    }

    filter(data, add = true) {
        if (!this.hasAttribute("data-not-filter")) {
            if(data?.id){
                if (add) {
                    if (this.hasAttribute("multiple")) {
                        this.dataS.notIn.push(data.id);
                    } else {
                        this.dataS.notIn = [data.id];
    
                        switch (getData(this, "data-filter")) {
                            case "shared": {
                                let match = this.name.match(/\[(.*?)\]/); // en caso que el name sea compartido en el form ejemplo account['sales']
                                let name = match ? match[1] : this.name;
                                dataAccounting.concat[name] = data.id;
                                dataAccounting.notIn = Object.values(
                                    dataAccounting.concat
                                );
    
                                break;
                            }
                            case "self": {
                                if (!dataAccounting.self[this.name]?.length) {
                                    dataAccounting.self[this.name] = [data.id];
                                } else {
                                    dataAccounting.self[this.name].push(data.id);
                                }
    
                                break;
                            }
                        }
                    }
                } else {
                    this.dataS.notIn = this.dataS.notIn.filter(
                        (item) => item != data.id
                    );
                }
            }
        }
    }

    reset() {
        this.dataS.notIn = [];
    }
}

window.customElements.define("select-accountingplan", SelectAccountingPlan, {
    extends: "select",
});
