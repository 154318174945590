import {
    b64Uri,
    defaultSelect2,
    getElement,
    getJson,
    setSelect2Ajax,
} from "../../../../../helpers";
import { CompanyModal as Company } from "../../../../settings/settings/companies/modals/store";

export class CustomerModal {
    static create = (modal, title, url) => {
        const Form = getElement("[data-form=form]", getElement(modal));

        Company.create(modal, title, url);

        defaultSelect2(
            Form.elements.paymentmethod_id,
            "contado",
            "paymentmethodUrlSelect"
        );
    };

    static edit = (modal, title, url, data) => {
        data = getJson(b64Uri(data, "decode"));

        Company.edit(modal, title, url, b64Uri(data.company));

        const Form = getElement("[data-form=form]", getElement(modal));

        if (data.paymentmethod) {
            setSelect2Ajax(
                Form.elements.paymentmethod_id,
                data.paymentmethod.id,
                data.paymentmethod.name
            );
        }

        Form.elements.description.value = data.description;
        Form.elements.code.value = data.code;

        // Como no existen más cuentas permito este código
        let customer_type = data.accountingplans.find((item) =>
            item.name.match(/\bcliente\b/i)
        ); // \b indica el principio y find e la palabra
        Form.elements.customer_type.value =
            customer_type?.accountingplan_id ?? "";
    };
}
