import $ from "jquery/dist/jquery";
import "select2";
import {
    b64Uri,
    bodyRequest,
    getData,
    requestOptions,
    setSelect2Ajax,
    readTranslations,
    getJson,
    resetSelect2Ajax,
} from "../helpers";

export class SelectDos {
    #select = null;
    #placeholder = "view.plugins.select2.placeholder";
    #noResults = "view.plugins.select2.noResults";
    #searching = "view.plugins.select2.searching";
    #clear = "view.plugins.select2.clear";

    #configSelect2 = {
        theme: "Bootstrap5",
        placeholder: "Elige una opción...",
        allowClear: false,
        width: "100%",
        language: {
            noResults: () => "No se encontraron resultado",
            searching: () => "Buscando...",
            clear: "Limpiar selección",
        },
    };

    constructor(object = {}) {
        this.#configSelect2 = { ...this.#configSelect2, ...object };
    }

    async selectDos(select) {
        this.#select = select;
        // Comienza desde el elemento select y sube hacia los Parents
        let parent = select;

        while (parent) {
            // Sigue subiendo hasta encontrar el modal
            parent = parent.parentElement;
            if (parent?.classList.contains("modal")) {
                break;
            }
        }

        if (parent) {
            this.#configSelect2.dropdownParent = $(parent);
        }

        // Traducir
        const Translations = await readTranslations();

        // no sé por qué no funciona, si tiene el placeholder, seguro tiene un conjuro negro,
        // necesitaré más tiempo para eliminar el maleficio
        this.#configSelect2.allowClear =
            select.hasAttribute("data-allow-clear");

        if (this.#configSelect2.placeholder) {
            this.#configSelect2.placeholder = Translations[this.#placeholder];
        }

        if (!this.#configSelect2.language) {
            this.#configSelect2.language = {};
        }

        this.#configSelect2.language.noResults = () =>
            Translations[this.#noResults];
        this.#configSelect2.language.searching = () =>
            Translations[this.#searching];
        this.#configSelect2.language.clear = Translations[this.#clear];

        return $(select)
            .select2(this.#configSelect2)
            .on("change", (e) => {
                // Este evento es predeterminado de los navegadores
                this.#select.setAttribute(
                    "data-default",
                    b64Uri($(select).select2("data")[0])
                );

                // Este evento es predeterminado de los navegadores por lo que es útil tenelo;
                // crear evento Personalizado
                const CE = new CustomEvent("aj:change", {
                    detail: { data: $(select).select2("data")[0] },
                    bubbles: true,
                    cancelable: true,
                });

                select.dispatchEvent(CE);
            })
            .on("select2:select", (e) => {
                this.#select.setAttribute(
                    "data-default",
                    b64Uri(e.params.data)
                );

                // crear el mismo evento
                const CE = new CustomEvent("aj:select2", {
                    detail: e.params,
                    bubbles: true,
                    cancelable: true,
                });

                select.dispatchEvent(CE);

                // solución para el multile evitar autocierre
                if (select.hasAttribute("multiple")) {
                    setTimeout(() => {
                        document.querySelector("button").focus();
                    }, 101);
                }

                // utilizar el CE.defaultPrevented para detener el evento en caso de utilizar preventDefault()
            })
            .on("select2:unselecting", (e) => {
                // crear el mismo evento
                const CE = new CustomEvent("aj:unselecting", {
                    detail: e.params.args,
                    bubbles: true,
                    cancelable: true,
                });

                select.dispatchEvent(CE);

                // solución para el multile evitar autocierre
                setTimeout(() => {
                    $(select).select2("close");
                    document.querySelector("button").focus();
                }, 100);

                // utilizar el CE.defaultPrevented para detener el evento en caso de utilizar preventDefault()
            })
            .on("select2:open", (e) => {
                // crear el mismo evento
                const CE = new CustomEvent("aj:open", {
                    detail: e.params,
                    bubbles: true,
                    cancelable: true,
                });

                select.dispatchEvent(CE);

                setTimeout(() => {
                    $(
                        ".select2-container--open .select2-search__field"
                    ).focus();
                }, 300);

                // Saber si tiene alor por defecto
                // let df = getData(select, "data-default");

                // $(".select2-search__field").on("keydown", function (e) {
                //     if (e.key === "Tab") {
                //         if (df) {
                //             let d = getJson(b64Uri(df, "decode"));
                //             setSelect2Ajax(select, d.id, d.text, d);
                //         } else {
                //             select.removeAttribute("data-default");
                //             resetSelect2Ajax(select);
                //         }
                //     }
                // });
            });
    }

    async default(search, url) {
        bodyRequest.set("search", search);
        let response = await fetch(getData(url), requestOptions);
        let data = await response.json();
        if (data.length) {
            setSelect2Ajax(this.#select, data[0].id, data[0].text);
            this.#select.setAttribute("data-default", b64Uri(data[0]));
        }
    }

    simpleDefault(data) {
        if (data.id) {
            setSelect2Ajax(this.#select, data.id, data.text);
        }
    }

    open() {
        $(this.#select).select2("open");

        setTimeout(() => {
            $(".select2-container--open .select2-search__field").focus();
        }, 1000);
    }
}
