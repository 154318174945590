import { dataCompany } from "../../../../../components/selectCompany";
import { b64Uri, getElement, getJson, resetForm } from "../../../../../helpers";
import $ from "jquery/dist/jquery";

export class ShareCustomer extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });
    }

    static shareCompanies(modal, title, url, data = null, view = null) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        dataCompany.companies = [];

        if (data && !view) {
            data = getJson(b64Uri(data, "decode"));
            Form.elements.customers.value = b64Uri([data]);
        }

        if (!data && view) {
            // Dejó de funcionar posiblemente lguna actualización del datatable
            const Table = $(getElement("[data-table=dt]")).DataTable();
            let data = Table.data().toArray();
            Form.elements.customers.value = b64Uri(data);
        }
    }
}

window.customElements.define("share-customer", ShareCustomer, {
    extends: "div",
});
