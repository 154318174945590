import {
    b64Uri,
    getData,
    getElement,
    getJson,
    setSelect2Ajax,
} from "../../../../../helpers";

class FormEntryTemplateModule extends HTMLElement {
    connectedCallback() {
        this.addEvents();
    }

    addEvents() {
        const Tbody = getElement("[name=tbodyentry]", this);
        let row = Tbody.lastElementChild.cloneNode(true);
        //Acciones
        const Form = getElement("[data-form=form]", this);

        Form.elements.save?.addEventListener("click", (e) => {
            if (Form.reportValidity()) {
                // Preparar líneas
                this.prepareLines();
                Form.submit();
            }
        });

        Form.elements.new_line?.addEventListener("click", () => {
            let row2 = row.cloneNode(true);

            getElement("[name=description]", row2).value = "";

            Tbody.appendChild(row2);
            let project = getJson(
                b64Uri(
                    getData(
                        getElement("[name=genproject]", this),
                        "data-default"
                    ),
                    "decode"
                )
            );

            this.fillLines(project);
        });

        Form.elements.genproject.addEventListener("aj:select2", (e) => {
            this.fillLines(e.detail.data);
        });

        Form.elements.description[0].addEventListener("change", (e) => {
            this.fillLines();
        });

        Tbody.addEventListener("aj:select2", (e) => {
            const Target = e.target;
            const Data = e.detail.data;
            if (Target.name == "accountingplan_id") {
                // Setear debe o haber
                const Column =
                    Target.parentElement.parentElement.parentElement
                        .nextElementSibling.firstElementChild;

                const Group = Data.accountinggroup;

                // Pendiente revisar esto solo se toma en cuento el grupo
                if (Group) {
                    switch (Data.rule) {
                        case 1:
                            if (Group.increment == "debit") {
                                Column.value = "credit";
                            } else {
                                Column.value = "debit";
                            }
                            break;

                        default:
                            Column.value = Group.increment;
                            break;
                    }
                }
            }
        });
    }

    fillLines(data = null) {
        const Tbody = getElement("[name=tbodyentry]", this);
        const Form = getElement("[data-form=form]", this);

        if (data?.id) {
            const IptProjects = Tbody.querySelectorAll("[name=project]");

            IptProjects.forEach((element) => {
                if (!element.value) {
                    setSelect2Ajax(element, data.id, data.name);
                }
            });
        }

        const IptDescriptions = Tbody.querySelectorAll("[name=description]");

        IptDescriptions.forEach((element) => {
            if (!element.value) {
                element.value = Form.elements.description[0].value;
            }
        });
    }

    prepareLines() {
        const Tbody = getElement("[name=tbodyentry]", this);
        const Form = getElement("[data-form=form]", this);
        let lines = [];

        Array.from(Tbody.children).forEach((row) => {
            const Project = getElement("[name=project]", row);
            const Description = getElement("[name=description]", row);
            const Account = getElement("[name=accountingplan_id]", row);
            const Column = getElement("[name=column]", row);

            // Validación extra
            if (Account.value && Column.value) {
                let line = {
                    accountingplan_id: Account.value,
                    accountingplan_data: b64Uri(
                        getData(Account, "data-default"),
                        "decode"
                    ),
                    accounting_column: Column.value,
                    amount: 0,
                    description: Description.value,
                    project_id: Project.value,
                };

                lines.push(line);
            }
        });

        Form.elements.lines.value = b64Uri(lines);
    }
}

window.customElements.define(
    "formentrytemplate-module",
    FormEntryTemplateModule,
    {
        extends: "section",
    }
);
