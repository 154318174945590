import {
    DateFormat,
    getDate,
    getElement,
    Management,
} from "../../../../helpers";

class VatSumaryModule extends HTMLElement {
    connectedCallback() {
        this.load();
        this.addEvents();
    }

    load() {
        const EntryDate = getElement("[name=entryDate]", this);
        const Year = getElement("[name=year]", this);
        const Period = getElement("[name=period]", this);
        let date = getDate(EntryDate.value, DateFormat);
        date.year(Year.value);

        if (Management.fiscal_period === "mensual") {
            date.month(parseInt(Period.value) - 1).endOf("month");
        } else {
            date.quarter(Period.value).endOf("quarter");
        }

        EntryDate.value = date.format(DateFormat);
    }

    addEvents() {
        this.addEventListener("change", (e) => {
            const Target = e.target;
            const EntryDate = getElement("[name=entryDate]", this);
            let date = getDate(EntryDate.value, DateFormat);

            if (Target.name === "year") {
                date.year(Target.value);
            }

            if (Target.name === "period") {
                if (Management.fiscal_period === "mensual") {
                    date.month(parseInt(Target.value) - 1).endOf("month");
                } else {
                    date.quarter(Target.value).endOf("quarter");
                }
            }

            EntryDate.value = date.format(DateFormat);
        });
    }
}

window.customElements.define("vatsumary-module", VatSumaryModule, {
    extends: "section",
});
