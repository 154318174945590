import {
    b64Uri,
    formatCurrency,
    getData,
    getElement,
    getJson,
    resetForm,
    setSelect2Ajax,
} from "../../../../../helpers";

export class TaxModal extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });

        Form.elements.type.addEventListener("input", (e) => {
            const Target = e.target;
            const Option = Target.options[Target.selectedIndex];
            const Labels = Form.querySelectorAll("[data-target]");
            const CntIsp = getElement("cntIsp", this);

            Labels.forEach((item) => {
                item.textContent = getData(
                    Option,
                    getData(item, "data-target")
                );
            });

            CntIsp.classList.add("d-none");

            const CntTag = getElement("cntTag", this);
            CntTag.classList.add("d-none");
            Form.elements.tag_sale.value = "";
            Form.elements.tag_purchase.value = "";

            Form.elements.isp.forEach((item) => {
                item.removeAttribute("required");
                item.checked = false;
            });

            if (Target.value == "ISP") {
                CntIsp.classList.remove("d-none");
                Form.elements.isp.forEach((item) =>
                    item.setAttribute("required", "")
                );
            }
        });

        Form.elements.isp.forEach((item) => {
            item.addEventListener("change", (e) => {
                const Target = e.target;
                const CntTag = getElement("cntTag", this);
                CntTag.classList.add("d-none");

                Form.elements.tag_sale.value = "";
                Form.elements.tag_purchase.value = "";

                if (Target.value == "intracomunitario") {
                    CntTag.classList.remove("d-none");
                }
            });
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        const CntIsp = getElement("cntIsp", getElement(modal));
        resetForm(Form, title, url);

        CntIsp.classList.add("d-none");
        const CntTag = getElement("cntTag", getElement(modal));
        CntTag.classList.add("d-none");
        Form.elements.tag_sale.value = "";
        Form.elements.tag_purchase.value = "";

        Form.elements.isp.forEach((item) => {
            item.removeAttribute("required");
            item.checked = false;
        });
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));
        const Form = getElement("[data-form=form]", getElement(modal));
        const CntIsp = getElement("cntIsp", getElement(modal));
        const CntTag = getElement("cntTag", getElement(modal));

        resetForm(Form, title, url);
        Form.elements.type.value = data.type;
        Form.elements.category.value = data.category;
        Form.elements.tax.value = formatCurrency(data.tax, "decimal");
        Form.elements.surcharge.value = formatCurrency(
            data.surcharge,
            "decimal"
        );
        Form.elements.description.value = data.description;

        data.accountingplans.forEach((item) => {
            const T = Form.elements[`account[${item.pivot.type}]`];
            setSelect2Ajax(T, item.id, item.number + " - " + item.name);
        });

        CntIsp.classList.add("d-none");
        CntTag.classList.add("d-none");

        Form.elements.isp.forEach((item) => {
            item.removeAttribute("required");
            item.checked = false;
        });

        if (data.type == "ISP") {
            CntIsp.classList.remove("d-none");
            Form.elements.isp.forEach((item) => {
                item.setAttribute("required", "");

                if (data.isp == item.value) {
                    item.checked = true;

                    if (data.isp == "intracomunitario") {
                        CntTag.classList.remove("d-none");
                        Form.elements.tag_sale.value = data.tag_sale ?? "";
                        Form.elements.tag_purchase.value =
                            data.tag_purchase ?? "";
                    }
                }
            });
        }
    }
}

window.customElements.define("tax-modal", TaxModal, {
    extends: "div",
});
