import tippy from "tippy.js";
import { CSRF, getData, getElement, Management } from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";
import { dataBank } from "../../../../components/selectBank";

class ImportErrors extends HTMLElement {
    connectedCallback() {
        this.load();
        this.addEvents();
    }

    load() {
        dataBank.companies = [Management.company_id];
        const DtElement = getElement("[data-table=dt]", this);

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            // Crear cuerpo scrolleable
            scrollY: "500px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("salesImportErrorsUrlDt"),
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "invoice", width: "10%", className: "text-center"  },
            { data: "code", width: "10%", className: "text-center"  },
            { data: "description" },
            { data: "user", width: "10%", className: "text-center" },
            { data: "date", width: "5%", className: "text-center" },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        configDt.drawCallback = () => {
            tippy(".tippy-tooltips");
        };

        const DtImports = new Dt(configDt);
        DtImports.dataTable(DtElement);
        // End DataTable
    }

    addEvents() {
        // console.log(this);
    }
}

window.customElements.define("import-errors", ImportErrors, {
    extends: "section",
});
