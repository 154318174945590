import { dataCustomer } from "../../../../components/selectCustomer";
import {
    CSRF,
    Management,
    formDataToObject,
    formatCurrency,
    getData,
    getDataFormToObject,
    getElement,
    resetFilter,
} from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";
import { ReceiptModal } from "../receipts/modals/store";

class SaleInvoiceModule extends HTMLElement {
    async connectedCallback() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);
        const BtnReceipt = getElement("#btnReceipt", this);
        const Form = getElement("[name=filter]");

        let filter = formDataToObject(Form);
        filter.type = "=";

        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            // Crear cuerpo scrolleable
            scrollY: "370px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("saleInvoicesUrlDt"),
            type: "POST",
            data: function (data) {
                data.filter = filter;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            {
                data: "check",
                width: "2%",
                className: "text-center",
                orderable: false,
            },
            { data: "ref", width: "5%", className: "text-center" },
            {
                data: "date_register",
                width: "6%",
                className: "text-center",
            },
            {
                data: "date_invoice",
                width: "6%",
                className: "text-center",
            },
            { data: "ref_invoice" },
            { data: "customer" },
            { data: "receipt_method" },
            { data: "date_receipt", width: "6%", className: "text-center" },
            {
                data: "amount",
                className: "text-end",
                render: (data, type, row) => {
                    return formatCurrency(data, "decimal");
                },
            },
            {
                data: "balance",
                className: "text-end",
                render: (data, type, row) => {
                    return formatCurrency(data, "decimal");
                },
            },
            { data: "received", width: "5%", className: "text-center" },
            { data: "accounted", width: "5%", className: "text-center" },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
            { data: "cif", className: "d-none" },
        ];

        const DtInvoices = new Dt(configDt);
        let T = await DtInvoices.dataTable(DtElement);
        // End DataTable

        BtnSearch?.addEventListener("click", () => {
            filter = getDataFormToObject(Form, filter);

            T.ajax.reload();

            BtnReceipt.classList.add("d-none");
        });

        BtnReset?.addEventListener("click", () => {
            filter = formDataToObject(Form);
            filter.type = "=";
            dataCustomer.companies = [];

            resetFilter(Form, filter);

            T.ajax.reload();

            BtnReceipt.classList.add("d-none");
        });

        this.addEventListener("click", (e) => {
            const Target = e.target;

            if (Target.name == "check") {
                const IptsCheck = document.querySelectorAll("[name=check]");
                let companySelected = getData(Target, "data-company");

                if (Target.checked) {
                    IptsCheck.forEach((item) => {
                        if (getData(item, "data-company") != companySelected) {
                            item.checked = false;
                            item.disabled = true;
                        }
                    });

                    BtnReceipt.classList.remove("d-none");
                } else {
                    const IptsChecked = Array.from(IptsCheck).filter(
                        (item) => item.checked
                    );

                    if (IptsChecked.length < 1) {
                        IptsCheck.forEach((item) => {
                            item.checked = false;
                            item.disabled = false;
                        });

                        BtnReceipt.classList.add("d-none");
                    }
                }
            }

            if (Target.id == "btnReceipt") {
                const RM = new ReceiptModal(getElement("#modalReceipt"));
                RM.create(
                    getData(Target, "data-bs-target"),
                    getData(Target, "title"),
                    getData(Target, "data-url")
                );
            }
        });

        document.body.addEventListener("aj:startYear", (e) => {
            BtnReceipt.classList.add("d-none");
            this.disabledByYear();
        });

        this.disabledByYear();
    }

    disabledByYear() {
        const BtnAdd = getElement("#btnAdd", this, false);
        let startYear = parseInt(localStorage.getItem(Management.slug));
        let years = Management.years.map((obj) => obj.year);
        // let minYear = Math.min(...years);
        let maxYear = Math.max(...years);

        if (maxYear - startYear > 1) {
            BtnAdd?.classList.add("d-none");
        } else {
            // if (maxYear - minYear > 1) {
            // if (maxYear - startYear > 0) {
            BtnAdd?.classList.remove("d-none");
            // }
            // }
        }
    }
}

window.customElements.define("saleinvoices-module", SaleInvoiceModule, {
    extends: "section",
});
