import {
    CSRF,
    DateFormat,
    formDataToObject,
    getData,
    getDataFormToObject,
    getDate,
    getElement,
    isDate,
    resetFilter,
} from "../../../helpers";
import { Dt } from "../../../plugins/datatable";

class LedgePurchasesModule extends HTMLElement {
    connectedCallback() {
        this.purchases();
    }

    async purchases() {
        const DtElement = getElement("[data-table=dtPurchases]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);
        const Form = getElement("[name=filter]", this);

        let filter = formDataToObject(Form);

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
            scrollY: "400px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("ledgerinvoicesUrlDt"),
            type: "POST",
            data: function (data) {
                data.projects = filter.projects;
                data.startDate = filter.startDate;
                data.endDate = filter.endDate;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };

        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            // {
            //     data: "date",
            //     width: "7%",
            //     render: (data, type, row, meta) => {
            //         if (isDate(data)) {
            //             let date = getDate(data);
            //             return date.format(DateFormat);
            //         } else {
            //             return data;
            //         }
            //     },
            // },
            {
                data: "date_invoice",
                width: "7%",
                render: (data, type, row, meta) => {
                    if (isDate(data)) {
                        let date = getDate(data);
                        return date.format(DateFormat);
                    } else {
                        return data;
                    }
                },
            },
            { data: "supplier_invoice" },
            { data: "cif" },
            { data: "supplier" },
            { data: "tax_base", className: "text-end" },
            { data: "tax", className: "text-end" },
            { data: "total_tax", className: "text-end" },
            { data: "withholdings", className: "text-end" },
            { data: "total_withholdings", className: "text-end" },
            { data: "total", className: "text-end" },
            { data: "project" },
        ];

        configDt.footerCallback = function (row, data, start, end, display) {
            var api = this.api();
            var json = api.ajax.json(); // Obtener los datos JSON devueltos del servidor

            if (json?.totals && api.column(0).footer()) {
                api.column(5).footer().textContent = json.totals.totalBase;
                api.column(7).footer().textContent = json.totals.totalTax;
                api.column(9).footer().textContent =
                    json.totals.totalWithholdings;
                api.column(10).footer().textContent = json.totals.total;
            }
        };

        const DtAp = new Dt(configDt);
        let T = await DtAp.dataTable(DtElement);
        // End DataTable

        //Acciones
        BtnSearch?.addEventListener("click", () => {
            filter = getDataFormToObject(Form, filter);

            T.ajax.reload();
        });

        BtnReset?.addEventListener("click", () => {
            filter = formDataToObject(Form);

            resetFilter(Form, filter);

            T.ajax.reload();
        });
    }
}

window.customElements.define("ledgerurchases-module", LedgePurchasesModule, {
    extends: "section",
});
