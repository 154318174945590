import { SelectDos } from "../plugins/select2";
import { getData, CSRF, getJson, b64Uri, setSelect2Ajax } from "../helpers";
// import ucwords from "ucwords";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectBalanceLine extends HTMLSelectElement {
    connectedCallback() {
        let configSelect = {
            ajax: {
                url: getData("balanceLineUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    return {
                        _token: CSRF,
                        search: param.term,
                        balance: getData(this[0], "data-balance"),
                    };
                },
                processResults: function (response) {
                    return {
                        results: response,
                    };
                },
            },
        };

        const SD = new SelectDos(configSelect);
        SD.selectDos(this);

        if (getData(this, "data-default")) {
            let Data = getJson(b64Uri(getData(this, "data-default"), "decode"));
            if (Data?.id) {
                setSelect2Ajax(this, Data.id, Data.number + " - " + Data.name);
            }
        }
    }

    reset() {
        // this.dataS.notIn = [];
    }
}

window.customElements.define("select-balanceline", SelectBalanceLine, {
    extends: "select",
});
