import Swal from "sweetalert2/dist/sweetalert2";
import {
    alertHTML,
    b64Uri,
    bodyRequest,
    getData,
    getElement,
    getJson,
    requestOptions,
    resetForm,
    resetSelect2Ajax,
    setSelect2Ajax,
    tryAsyncFetch,
} from "../../../../../../helpers";

const DataAccountingplanModal = { validate: true };
export class AccountingplanModal extends HTMLDivElement {
    connectedCallback() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", async () => {
            if (
                this.validAccount() & Form.reportValidity() &&
                (await this.isExist())
            ) {
                if (getData(Form.elements.save, "data-request") == "http") {
                    Form.submit();
                } else {
                    let body = new FormData(Form);
                    requestOptions.body = body;
                    let response = await tryAsyncFetch(
                        Form.action,
                        requestOptions
                    );
                    requestOptions.body = bodyRequest; // regresar el bodyRequest predeterminado

                    if (response.errors) {
                        alertHTML(response.message);
                    } else {
                        Form.elements.closeModal.click();
                        alertHTML(getData(Form, "data-message"), "success");
                        // El modo pro solo si lo piden, aquí lanzar un custom event para devolver la data y poder setear el target
                    }
                }
            }
        });

        Form.elements.main.addEventListener("click", () => {
            this.removeAttributes();

            if (Form.elements.main.checked) {
                this.setAttributes();
            }
        });

        Form.elements.is_parent.addEventListener("click", () => {
            const IsParent = Form.elements.is_parent.checked;
            const Account = Form.elements.number;

            Account.setAttribute(
                "minlength",
                getData(this, "data-account-length")
            );

            if (IsParent) {
                Account.removeAttribute("minlength");
            }
        });

        Form.addEventListener("aj:select2", (e) => {
            if (e.target.name == "accountingplan_id") {
                let data = e.detail.data;
                const Group = Form.elements.accountinggroup_id;
                if (data.accountinggroup) {
                    setSelect2Ajax(
                        Group,
                        data.accountinggroup.id,
                        data.accountinggroup.name
                    );
                }
            }
        });

        Form.elements.number.addEventListener("change", async (e) => {
            const IptAccount = Form.elements.number;
            let value = IptAccount.value.replace(/[.]+/g, ".");
            let number = value.split(".");

            if (number.length > 1) {
                let max = IptAccount.getAttribute("maxlength");
                let totalLength = max - number.join("").length;
                let zerosToAdd = Math.floor(totalLength / (number.length - 1));
                let finalNumber = number
                    .map((part, index) => {
                        if (index < number.length - 1) {
                            return part + "0".repeat(zerosToAdd);
                        }
                        return part;
                    })
                    .join("");

                if (finalNumber.length < max) {
                    let diff = max - finalNumber.length;
                    let lastPartLength = number[number.length - 1].length;

                    finalNumber =
                        finalNumber.slice(0, -lastPartLength) +
                        "0".repeat(diff) +
                        finalNumber.slice(-lastPartLength);
                }

                if (finalNumber.length == max) {
                    IptAccount.value = finalNumber;
                }
            }

            if (this.validAccount()) {
                // Buscar solo las cuentas permitidas
                if (
                    !IptAccount.hasAttribute("minlength") ||
                    (IptAccount.hasAttribute("minlength") &&
                        IptAccount.value.length ==
                            IptAccount.getAttribute("minlength"))
                ) {
                    this.isExist();
                }
            }
        });

        Form.elements.number.addEventListener("input", (e) => {
            this.validAccount();
        });
    }

    static create(modal, url, title, message = null) {
        modal = getElement(modal);
        modal.removeAttributes();
        const Form = getElement("[data-form=form]", modal);

        if (message) {
            Form.setAttribute("data-message", message);
        }

        DataAccountingplanModal.validate = true;

        resetForm(Form, title, url);
    }

    static edit(modal, url, title, data) {
        DataAccountingplanModal.validate = false;
        data = JSON.parse(atob(data));
        modal = getElement(modal);
        modal.removeAttributes();
        const form = getElement("[data-form=form]", modal);
        resetForm(form, title, url);

        form.elements.is_parent.checked = data.is_parent;

        if (data.is_parent && !data.accountingplan_id) {
            form.elements.main.click();
        }

        form.elements.name.value = data.name;
        form.elements.number.value = data.number;
        if (data.accountingplan)
            setSelect2Ajax(
                form.elements.accountingplan_id,
                data.accountingplan.id,
                data.accountingplan.number + " - " + data.accountingplan.name
            );
        if (data.accountinggroup)
            setSelect2Ajax(
                form.elements.accountinggroup_id,
                data.accountinggroup.id,
                data.accountinggroup.name
            );
        // if (data.journal)
        //     setSelect2Ajax(
        //         form.elements.journal_id,
        //         data.journal.id,
        //         data.journal.code + " - " + data.journal.name
        //     );
    }

    validAccount() {
        /*
         * Pimero validar que el comienzo de la cuenta no pertenezca al siguiente grupo
         * Clientes = 430, Proveedores = 400, Acreedores = 410, Bancos = 572
         */
        const Form = getElement("[data-form=form]", this);
        let invalidAccount = getData(Form, "data-invalid-account").split(",");
        const IptAccount = Form.elements.number;
        let valid = invalidAccount.some(
            (item) => item == IptAccount.value.substring(0, 3)
        );

        IptAccount.setCustomValidity("");

        if (valid) {
            IptAccount.setCustomValidity(
                getData(IptAccount, "data-invalid-account")
            );
        }

        return !valid;
    }

    async isExist() {
        if (DataAccountingplanModal.validate) {
            const Form = getElement("[data-form=form]", this);
            const IptAccount = Form.elements.number;
            let response = await this.find(IptAccount.value, "number");

            if (response.success) {
                const Data = response.success;

                let translate = getJson(
                    getJson(b64Uri(getData(IptAccount, "data-exist"), "decode"))
                );
                let title = `(${Data.number}) - ${Data.name}`;
                let text = translate.text;
                let confirmButtonText = translate.add;
                let cancelButtonText = translate.cancel;

                let nextAc = Form.elements.number.value;
                if (Data.accountingplan?.accountingplans.length) {
                    // Esta fórmula no es precisa per funcional
                    nextAc = ++Data.accountingplan.accountingplans[0].number;
                    cancelButtonText = `${translate.nextAc}  (${nextAc})`;
                }

                const result = await Swal.fire({
                    title: title,
                    text: text,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: confirmButtonText,
                    cancelButtonText: cancelButtonText,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                });

                if (result.isConfirmed) {
                    await fetch(
                        getData(IptAccount, "data-url-attach") + Data.id,
                        requestOptions
                    );

                    Form.elements.closeModal.click();

                    if (getData(Form.elements.save, "data-request") == "http") {
                        window.location.reload();
                    }
                } else {
                    Form.elements.number.value = nextAc;
                }

                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    removeAttributes() {
        const Form = getElement("[data-form=form]", this);
        let main = Form.elements.main.checked;
        const IsParent = Form.elements.is_parent;
        const Account = Form.elements.number;
        const Accountingplan = Form.elements.accountingplan_id;
        const Accountinggroup = Form.elements.accountinggroup_id;

        IsParent.checked = main;
        IsParent.removeAttribute("disabled");
        Account.setAttribute("minlength", getData(this, "data-account-length"));
        Accountingplan.setAttribute("required", "");
        Accountingplan.removeAttribute("disabled");
        Accountinggroup.setAttribute("required", "");
        resetSelect2Ajax(Accountinggroup);
    }

    setAttributes() {
        const Form = getElement("[data-form=form]", this);
        const IsParent = Form.elements.is_parent;
        const Account = Form.elements.number;
        const Accountingplan = Form.elements.accountingplan_id;
        const Accountinggroup = Form.elements.accountinggroup_id;

        IsParent.setAttribute("disabled", "");
        Accountingplan.removeAttribute("required");
        Accountingplan.setAttribute("disabled", "");
        resetSelect2Ajax(Accountingplan);
        Account.removeAttribute("minlength");
        Accountingplan.removeAttribute("required");
        Accountinggroup.removeAttribute("required");
    }

    async find(value, attribute) {
        bodyRequest.set("search", value);
        bodyRequest.set("attribute", attribute);

        let response = await tryAsyncFetch(
            getData(getElement("accountingplansdeUrlSearch")),
            requestOptions
        );

        return response;
    }
}

window.customElements.define("accountingplan-modal", AccountingplanModal, {
    extends: "div",
});
