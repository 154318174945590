import { SelectDos } from "../plugins/select2";
import { getData, CSRF, setSelect2Ajax, b64Uri, getJson } from "../helpers";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectSupplier extends HTMLSelectElement {
    dataS = { notIn: [] };

    async connectedCallback() {
        let self = this;
        let configSelect = {
            ajax: {
                url: getData("suppliersUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    return {
                        _token: CSRF,
                        search: param.term,
                        notIn: self.dataS.notIn,
                    };
                },
                processResults: function (response) {
                    // response.forEach((element) => {
                    //     element.id = element.alpha2;
                    // });
                    return {
                        results: response,
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        const S = await SD.selectDos(this);

        S[0]?.addEventListener("aj:select2", (e) => {
            const Data = e.detail.data;

            this.filter(Data);
        });

        S[0]?.addEventListener("aj:change", (e) => {
            const Data = e.detail.data;

            this.filter(Data);
        });

        S[0]?.addEventListener("aj:unselecting", (e) => {
            const Data = e.detail.data;

            this.filter(Data, false);
        });

        if (S[0].hasAttribute("data-open")) {
            SD.open();
        }

        if (getData(this, "data-default")) {
            let Data = getJson(b64Uri(getData(this, "data-default"), "decode"));

            if (Data?.id) {
                setSelect2Ajax(
                    this,
                    Data.id,
                    `${Data.name} (${Data.cif})`,
                    Data
                );

                this.filter(Data);
            }
        }
    }

    filter(data, add = true) {
        if (!this.hasAttribute("data-not-filter")) {
            if (data?.id) {
                if (add) {
                    if (this.hasAttribute("multiple")) {
                        this.dataS.notIn.push(data.id);
                    } else {
                        this.dataS.notIn = [data.id];
                    }
                } else {
                    this.dataS.notIn = this.dataS.notIn.filter(
                        (item) => item != data.id
                    );
                }
            }
        }
    }

    reset() {
        this.dataS.notIn = [];
    }
}

window.customElements.define("select-supplier", SelectSupplier, {
    extends: "select",
});
