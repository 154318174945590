import {
    DecimalLength,
    Management,
    alertHTML,
    tryAsyncFetch,
    b64Uri,
    bodyRequest,
    cleanFloat,
    getData,
    getElement,
    getJson,
    requestOptions,
    setSelect2Ajax,
    resetSelect2Ajax,
    formatCurrency,
    cleanNumberInput,
} from "../../../../helpers";
import { dataBank } from "../../../../components/selectBank";
import moment from "moment/moment";
import { map } from "jquery";
import { Dt } from "../../../../plugins/datatable";

export class FormPurchaseReturnModule extends HTMLElement {
    async connectedCallback() {
        dataBank.companies = [Management.company_id]; // filtrar las cuentas bancarias por management
        this.disabledByYear();
        this.load();
        this.addEvents();
        // this.refillLines();
        this.calcTotal();
        this.calcTaxes();
    }

    load() {
        const DtElement = getElement("[data-table=dt]", this);

        // Start DataTable
        let configDt = {
            // Crear cuerpo scrolleable
            ordering: false,
            lengthChange: false,
            stateSave: false,
            paging: false,
            searching: false,
            info: false,
            scrollY: "150px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        const DT = new Dt(configDt);
        DT.dataTable(DtElement);
        // End DataTable
    }

    addEvents() {
        const Form = getElement("form", this);
        const TBody = getElement("tBodyLines", this);

        if (Form.elements.supplier_company_id.hasAttribute("data-default")) {
            let provider = getJson(
                b64Uri(
                    getData("[name=supplier_company_id]", "data-default", this),
                    "decode"
                )
            );
            // Establecer el método de pago
            let paymentmethod = provider.paymentmethod;
            if (
                paymentmethod &&
                !Form.elements.paymentmethod_id.hasAttribute("data-default")
            ) {
                setSelect2Ajax(
                    Form.elements.paymentmethod_id,
                    paymentmethod.id,
                    paymentmethod.name,
                    paymentmethod
                );

                // Simular promesa
                const checkValue = setInterval(() => {
                    if (Form.elements.date_invoice.value.trim() !== "") {
                        // Si ya tiene un valor
                        clearInterval(checkValue); // Detener la verificación
                        // Clacular la fecha de pago
                        this.calcPaymentDate(Form, paymentmethod);
                    }
                }, 500);
            }
        }

        ["change", "aj:select2", "aj:daterangepicker"].forEach((event) => {
            this.addEventListener(event, async (e) => {
                if (e.target.name == "supplier_company_id") {
                    const Provider = e.detail.data;

                    // Desplegar las cuentas
                    Form.elements.accountingplan_id.innerHTML = "";
                    Provider.accountingplans.forEach((item) => {
                        Form.elements.accountingplan_id.innerHTML = `<option value="${item.id}">${item.number} - ${item.name}</option>`;
                    });

                    // Establecer el método de pago
                    let paymentmethod = Provider.paymentmethod;
                    if (paymentmethod) {
                        setSelect2Ajax(
                            Form.elements.paymentmethod_id,
                            paymentmethod.id,
                            paymentmethod.name,
                            paymentmethod
                        );
                    }

                    // Establecer proyecto
                    let project = Provider?.with_projects?.find(
                        (item) => item.id
                    );
                    if (project) {
                        setSelect2Ajax(
                            Form.elements.project_id,
                            project.id,
                            project.name,
                            project
                        );
                    }

                    // Clacular la fecha de pago
                    this.calcPaymentDate(Form, paymentmethod);

                    // this.clearByChangeSupplier();
                }

                if (e.target.name == "tax" || e.target.name == "withholding") {
                    this.calcLine(e.target);
                }

                if (e.target.name == "paymentmethod_id") {
                    this.calcPaymentDate(Form, e.detail.data);
                }

                if (e.target.name == "date_invoice") {
                    let paymentmethod = getJson(
                        b64Uri(
                            getData(
                                Form.elements.paymentmethod_id,
                                "data-default",
                                Form
                            ),
                            "decode"
                        )
                    );

                    this.calcPaymentDate(Form, paymentmethod);
                }

                this.refillLines();
                this.calcTaxes();
                await this.draft();
            });
        });

        let row = TBody.lastElementChild.cloneNode(true);

        Form.elements.new_line?.addEventListener("click", () => {
            let row2 = row.cloneNode(true);
            getElement("[name=project]", row2).removeAttribute("data-default"); // remover datos por defecto para que tome el proyecto seleccionado
            TBody.appendChild(row2);
            getElement("[name=type]", row2).value =
                "App\\Models\\Accountingplan";
            getElement("[name=quantity]", row2).value = "";
            getElement("[name=unit_price]", row2).value = "";
            getElement("[name=tax_base]", row2).value = "";
            getElement("[name=total_tax]", row2).value = "";
            getElement("[name=total_line]", row2).value = "";
            this.refillLines();
            const IptIsp = getElement("[name=isp]");
            this.isp(IptIsp, true);
            this.calcTotal();
            this.calcTaxes();
            getElement("[name=quantity]", row2).focus();
        });

        Form.elements.save?.addEventListener("click", async () => {
            Array.from(Form.elements).forEach((element) => {
                if (element.classList.contains("noValidateDraft")) {
                    if (element.name === "bank") {
                        element.removeAttribute("required");
                    } else {
                        element.setAttribute("required", "");
                    }
                }
            });

            this.prepareLines();

            if (Form.reportValidity() && (await this.validateData())) {
                this.calcTaxes();
                if (
                    Form.elements.accounted?.checked ||
                    Form.elements.pay?.checked
                ) {
                    Form.submit();
                } else {
                    Form.submit();
                }
            }
        });

        TBody.addEventListener("input", async (e) => {
            const IptQuantity = e.target.closest("[name=quantity]");
            const IptPrice = e.target.closest("[name=unit_price]");
            const IptTotal = e.target.closest("[name=total_line]");

            if (IptQuantity || IptPrice) {
                this.calcLine(e.target);
            }

            if (IptTotal) {
                this.calcTotal();
            }
        });

        TBody.addEventListener("cl:deleteRow", async (e) => {
            if (!TBody.children.length) {
                const BtnNewLine = getElement("[name=new_line]", this);
                BtnNewLine?.click();
            }

            this.calcTaxes();
            await this.draft();
        });

        document.body.addEventListener("aj:startYear", (e) => {
            const Form = getElement("form", this);
            let purchasereturn = getJson(
                b64Uri(Form.elements.purchasereturn.value, "decode")
            );

            this.disabledByYear();

            if (purchasereturn?.id) {
                let startYear = parseInt(localStorage.getItem(Management.slug));
                let dateInvoice = moment(purchasereturn.date_invoice);
                if (dateInvoice.year() != startYear) {
                    // esto es la forma más simple aunque un poco vulgar
                    localStorage.setItem(Management.slug, dateInvoice.year());
                    window.location.reload();
                }
            }
        });

        this.addEventListener("change", (e) => {
            const IptIsp = e.target.closest("[name=isp]");

            if (IptIsp) {
                this.isp(IptIsp);
            }
        });
    }

    calcPaymentDate(form, paymentmethod) {
        // Clacular la fecha de pago
        let format = getData(form.elements.date_payment, "data-format");
        let date = moment(
            form.elements.date_invoice.value || undefined,
            format
        );
        let days = paymentmethod?.days ?? 0;

        if (days < 30) {
            date.add(days, "days");
        } else {
            // Calcular cuántos meses completos y días extras
            let months = Math.floor(days / 30);
            let diffDays = days % 30;
            // Confirmar si la fecha a evaluar es fin de mes clonar para evitar incoherencias
            let endOfMonth = date.isSame(date.clone().endOf("month"), "day");
            // Sumar meses completos después de la evualuación
            date.add(months, "months");
            // Si es fin de mes, asignarlo a la fecha
            if (endOfMonth) {
                date.endOf("month");
            }
            // Sumar los días extra
            date.add(diffDays, "days");
        }

        if (date.isValid()) {
            form.elements.date_payment.value = date.format(format);
        }
    }

    calcLine(target) {
        const Parent = target.closest("tr");
        const IptQuantity = getElement("[name=quantity]", Parent);
        const IptPrice = getElement("[name=unit_price]", Parent);
        const Tax = getJson(
            b64Uri(getData("[name=tax]", "data-default", Parent), "decode")
        );
        const Withholding = getJson(
            b64Uri(
                getData("[name=withholding]", "data-default", Parent),
                "decode"
            )
        );
        const IptTaxBase = getElement("[name=tax_base]", Parent);
        const IptTotalWithholding = getElement(
            "[name=total_withholding]",
            Parent
        );
        const IptTotalTax = getElement("[name=total_tax]", Parent);
        const IptTotal = getElement("[name=total_line]", Parent);
        let subTotal =
            cleanFloat(IptQuantity.value) * cleanFloat(IptPrice.value);
        let taxBase =
            subTotal *
            (cleanFloat(formatCurrency(Tax?.tax ?? 0, "decimal")) / 100);
        let withholdingBase =
            subTotal *
            (cleanFloat(formatCurrency(Withholding?.tax ?? 0, "decimal")) /
                100);

        IptTaxBase.value = formatCurrency(
            subTotal.toFixed(DecimalLength),
            "decimal"
        );
        IptTotalWithholding.value = formatCurrency(
            withholdingBase.toFixed(DecimalLength),
            "decimal"
        );
        IptTotalTax.value = formatCurrency(
            taxBase.toFixed(DecimalLength),
            "decimal"
        );
        let total = subTotal - withholdingBase + taxBase;

        const IptIsp = getElement("[name=isp]");

        if (IptIsp.checked) {
            total -= taxBase;
        }

        IptTotal.value = formatCurrency(
            total.toFixed(DecimalLength),
            "decimal"
        );

        this.calcTotal();
    }

    calcTotal() {
        const Form = getElement("form", this);
        const TotalLines = Form.querySelectorAll("[name=total_line]");
        let total = 0;

        TotalLines.forEach((item) => {
            total += cleanFloat(item.value);
        });

        return total;
    }

    async validateData() {
        const Form = getElement("form", this);
        let validate = Form.checkValidity();
        if (validate) {
            let body = new FormData(Form);
            requestOptions.body = body;
            let response = await tryAsyncFetch(
                getData(Form, "data-url-validate"),
                requestOptions
            );

            if (response.errors) {
                validate = false;

                let msg = Object.values(response.errors)
                    .flat()
                    .flatMap((item) => `<li>${item}</li>`)
                    .join("");

                alertHTML(`<ul>${msg}</ul>`);
            }
        }

        return validate;
    }

    async draft() {
        if (this.disabledByYear()) {
            const Form = getElement("form", this);
            let purchasereturn = getJson(
                b64Uri(Form.elements.purchasereturn.value, "decode")
            );

            if (purchasereturn?.error || purchasereturn?.ref === "borrador") {
                Array.from(Form.elements).forEach((element) => {
                    if (element.classList.contains("noValidateDraft")) {
                        element.removeAttribute("required");
                    }
                });
                if (Form.checkValidity()) {
                    let body = new FormData(Form);
                    body.delete("accounted");
                    body.delete("pay");
                    body.set("lines", b64Uri(this.prepareLines()));
                    requestOptions.body = body;
                    let response = await tryAsyncFetch(
                        Form.action,
                        requestOptions
                    );
                    requestOptions.body = bodyRequest; // regresar el bodyRequest predeterminado
                    if (!response.errors) {
                        Form.elements.purchasereturn.value = b64Uri(
                            response.data
                        );
                        Form.setAttribute(
                            "data-url-validate",
                            getData(Form, "data-url-validate") +
                                "/" +
                                response.data.id
                        );
                    } else {
                        alertHTML(response.message, "error");
                    }
                }
            }
        }
    }

    clearByChangeSupplier() {
        const TBody = getElement("tBodyLines", this);
        // TBody.querySelectorAll("[name=account]")?.forEach((element) => {
        //     resetSelect2Ajax(element);
        // });

        TBody.querySelectorAll("[name=tax]")?.forEach((element) => {
            resetSelect2Ajax(element);
        });

        TBody.querySelectorAll("[name=project]")?.forEach((element) => {
            resetSelect2Ajax(element);
        });

        TBody.querySelectorAll("[name=description]")?.forEach((element) => {
            element.value = "";
        });
    }

    refillLines() {
        let provider = getJson(
            b64Uri(
                getData("[name=supplier_company_id]", "data-default", this),
                "decode"
            )
        );

        let inv = getData("[name=supplier_invoice]", "value", this);
        const TBody = getElement("tBodyLines", this);
        const IptIsp = getElement("[name=isp]");

        let tax = provider?.taxes?.find((item) => item.type === "IVA");
        if (tax && !IptIsp.checked) {
            TBody.querySelectorAll("[name=tax]")?.forEach((element) => {
                if (!element.value) {
                    setSelect2Ajax(
                        element,
                        tax.id,
                        tax.text ??
                            cleanNumberInput(tax.tax) + "% " + tax.description,
                        tax
                    );
                }
            });
        }

        let isp = provider?.taxes?.find((item) => item.type === "ISP");
        if (isp && IptIsp.checked) {
            TBody.querySelectorAll("[name=tax]")?.forEach((element) => {
                if (!element.value) {
                    setSelect2Ajax(
                        element,
                        isp.id,
                        isp.text ??
                            cleanNumberInput(isp.tax) + "% " + isp.description,
                        isp
                    );
                }
            });
        }

        // let project = provider?.with_projects?.find((item) => item.id);
        let project = getJson(
            b64Uri(getData("[name=project_id]", "data-default"), "decode")
        );
        if (project) {
            TBody.querySelectorAll("[name=project]")?.forEach((element) => {
                if (!element.value || element.value == 30) {
                    setSelect2Ajax(element, project.id, project.name, project);
                }
            });
        }

        if (inv && provider) {
            TBody.querySelectorAll("[name=description]")?.forEach((element) => {
                if (!element.value) {
                    element.value =
                        "S/FRA - " +
                        inv +
                        " - " +
                        (provider?.name ?? provider?.text ?? "");
                }
            });
        }
    }

    isp(element, event = false) {
        let provider = getJson(
            b64Uri(
                getData("[name=supplier_company_id]", "data-default", this),
                "decode"
            )
        );
        const TBody = getElement("tBodyLines", this);

        let isp = provider?.taxes?.find((item) => item.type === "ISP");
        let tax = provider?.taxes?.find((item) => item.type === "IVA");

        TBody.querySelectorAll("[name=tax]")?.forEach((item, key) => {
            if (!event) {
                resetSelect2Ajax(item);
            }

            if (element.checked) {
                item.setAttribute("data-type", "ISP");
                if (isp) {
                    setSelect2Ajax(
                        item,
                        isp.id,
                        isp.text ??
                            cleanNumberInput(isp.tax) + "% " + isp.description,
                        isp
                    );
                }
            } else {
                item.setAttribute("data-type", "IVA");
                if (tax) {
                    setSelect2Ajax(
                        item,
                        tax.id,
                        tax.text ??
                            cleanNumberInput(tax.tax) + "% " + tax.description,
                        tax
                    );
                }
            }

            this.calcLine(item);
            this.calcTotal();
            this.calcTaxes();
        });
    }

    prepareLines(taxes = false) {
        const Form = getElement("form", this);
        const TBody = getElement("tBodyLines", this);
        let dataForm = [];

        Array.from(TBody.children).forEach((item) => {
            const IptType = getElement("[name=type]", item);
            const IptAccount = getJson(
                b64Uri(
                    getData("[name=account]", "data-default", item),
                    "decode"
                )
            );
            const IptProduct = getJson(
                b64Uri(
                    getData("[name=product]", "data-default", item),
                    "decode"
                )
            );
            const IptQuantity = getElement("[name=quantity]", item);
            const IptPrice = getElement("[name=unit_price]", item);
            const Tax = getJson(
                b64Uri(getData("[name=tax]", "data-default", item), "decode")
            );
            const Withholding = getJson(
                b64Uri(
                    getData("[name=withholding]", "data-default", item),
                    "decode"
                )
            );
            const IptTotalLine = getElement("[name=total_line]", item);
            const description = getElement("[name=description]", item);
            const project = getElement("[name=project]", item);

            const data = {
                element_type: IptType.value,
                element_id: IptAccount?.id ?? IptProduct?.id,
                description: description.value,
                quantity: cleanFloat(IptQuantity.value),
                unit_price: cleanFloat(IptPrice.value),
                total_line: cleanFloat(IptTotalLine.value),
                project_id: project.value,
                tax_id: Tax?.id,
                tax: Tax,
                withholding_id: Withholding?.id,
                withholding: Withholding,
            };

            dataForm.push(data);
        });

        if (taxes) {
            dataForm = dataForm.filter(
                (item) =>
                    item.quantity &&
                    item.unit_price &&
                    (item.tax_id || item.withholding_id)
            );
        } else {
            dataForm = dataForm.filter(
                (item) =>
                    item.element_type &&
                    item.element_id &&
                    item.quantity &&
                    item.unit_price &&
                    item.total_line &&
                    item.project_id &&
                    item.tax_id &&
                    item.withholding_id
            );
        }

        Form.elements.lines.value = b64Uri(dataForm);

        return dataForm;
    }

    calcTaxes() {
        const CntTaxes = getElement("cntTaxes", this);
        const IptIsp = getElement("[name=isp]");
        CntTaxes.innerHTML = "";

        let lines = this.prepareLines(true);
        lines.sort((a, b) => parseFloat(a.tax.tax) - parseFloat(b.tax.tax));

        let taxes = lines.reduce((acc, item) => {
            if (item.tax.id) {
                item.tax.description =
                    item.tax?.text ??
                    `${cleanNumberInput(item.tax.tax)}% ${
                        item.tax.description
                    }`;
                let index = acc.findIndex((i) => i.id == item.tax.id);

                item.tax.tax_base = item.quantity * item.unit_price;
                item.tax.total_tax =
                    item.tax.tax_base *
                    (cleanFloat(formatCurrency(item.tax.tax, "decimal")) / 100);

                if (IptIsp.checked) {
                    item.tax.total_tax -= item.tax.total_tax;
                }

                if (index >= 0) {
                    acc[index].tax_base += item.tax.tax_base;
                    acc[index].total_tax += item.tax.total_tax;
                } else {
                    acc.push(item.tax);
                }
            }
            return acc;
        }, new map());

        taxes.forEach((item) => {
            CntTaxes.innerHTML += `<div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${
                                                item.description
                                            }" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${formatCurrency(
                                                item.tax_base.toFixed(
                                                    DecimalLength
                                                ),
                                                "decimal"
                                            )}" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${formatCurrency(
                                                item.total_tax.toFixed(
                                                    DecimalLength
                                                ),
                                                "decimal"
                                            )}" />
                                        </div>
                                    </div>`;
        });

        //Parche por implementación de retenciones
        lines.sort(
            (a, b) =>
                parseFloat(a.withholding.tax) - parseFloat(b.withholding.tax)
        );

        let withholdings = lines.reduce((acc, item) => {
            if (item.withholding.id) {
                item.withholding.description =
                    item.withholding?.text ??
                    `${cleanNumberInput(item.withholding.tax)}% ${
                        item.withholding.description
                    }`;
                let index = acc.findIndex((i) => i.id == item.withholding.id);

                item.withholding.tax_base = item.quantity * item.unit_price;
                item.withholding.total_withholding =
                    item.withholding.tax_base *
                    (cleanFloat(
                        formatCurrency(item.withholding.tax, "decimal")
                    ) /
                        100);
                if (index >= 0) {
                    acc[index].tax_base += item.withholding.tax_base;
                    acc[index].total_withholding +=
                        item.withholding.total_withholding;
                } else {
                    acc.push(item.withholding);
                }
            }
            return acc;
        }, new map());

        withholdings.forEach((item) => {
            CntTaxes.innerHTML += `<div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${
                                                item.description
                                            }" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${formatCurrency(
                                                item.tax_base.toFixed(
                                                    DecimalLength
                                                ),
                                                "decimal"
                                            )}" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${formatCurrency(
                                                item.total_withholding.toFixed(
                                                    DecimalLength
                                                ),
                                                "decimal"
                                            )}" />
                                        </div>
                                    </div>`;
        });

        // if (withholdings?.tax) {
        //     let t = taxes.filter((item) => item.total_tax);
        //     let st = t.reduce((acc, item) => (acc += item.tax_base), 0);
        //     totalWh = st * (withholdings.tax / 100);

        //     CntTaxes.innerHTML += `<div class="col-4">
        //                                 <div class="input-group input-group-sm flex-nowrap mt-1">
        //                                     <input type="text" class="form-control form-control-sm" data-number disabled value="${
        //                                         withholdings.description
        //                                     }" />
        //                                 </div>
        //                             </div>
        //                             <div class="col-4">
        //                                 <div class="input-group input-group-sm flex-nowrap mt-1">
        //                                     <input type="text" class="form-control form-control-sm" data-number disabled value="${isNumber(
        //                                         cleanFloat(
        //                                             st.toFixed(DecimalLength)
        //                                         )
        //                                     )}" />
        //                                 </div>
        //                             </div>
        //                             <div class="col-4">
        //                                 <div class="input-group input-group-sm flex-nowrap mt-1">
        //                                     <input type="text" class="form-control form-control-sm" data-number disabled value="${isNumber(
        //                                         cleanFloat(
        //                                             totalWh.toFixed(
        //                                                 DecimalLength
        //                                             )
        //                                         )
        //                                     )}" />
        //                                 </div>
        //                             </div>`;
        // }

        let total = this.calcTotal();
        // Necesario porque se implantó datatble
        const lastTotalInput = Array.from(
            document.querySelectorAll("[name='total']")
        ).pop();

        lastTotalInput.value = formatCurrency(
            total.toFixed(DecimalLength),
            "decimal"
        );
    }

    disabledByYear() {
        const Form = getElement("form", this);
        let startYear = parseInt(localStorage.getItem(Management.slug));
        let years = Management.years.map((obj) => obj.year);
        let maxYear = Math.max(...years);
        let disableByYear = false;

        if (maxYear - startYear > 1) {
            Form.elements.new_line?.classList.add("d-none");
            Form.elements.save?.classList.add("d-none");
            disableByYear = false;
        } else {
            Form.elements.new_line?.classList.remove("d-none");
            Form.elements.save?.classList.remove("d-none");
            disableByYear = true;
        }

        return disableByYear;
    }
}

window.customElements.define(
    "formpurchasereturns-module",
    FormPurchaseReturnModule,
    {
        extends: "section",
    }
);
