import {
    alertHTML,
    bodyRequest,
    formDataToObject,
    getData,
    getDataFormToObject,
    getElement,
    requestOptions,
    resetFilter,
    tryAsyncFetch,
} from "../../../helpers";

class LedgerModule extends HTMLElement {
    connectedCallback() {
        this.load();
        this.events();
    }

    load() {
        // console.log(this);
    }

    events() {
        const Form = getElement("[name=filter]", this);
        let filter = formDataToObject(Form);

        document.body.addEventListener("aj:startYear", (e) => {
            let data = e.detail;
            if (data.event) {
                window.location.reload();
            }
        });

        Form.elements.search.addEventListener("click", (e) => {
            filter = getDataFormToObject(Form, filter);

            Form.submit();
        });

        Form.elements.reset.addEventListener("click", (e) => {
            filter = formDataToObject(Form);

            resetFilter(Form, filter);

            Form.submit();
        });

        this.addEventListener("click", async (e) => {
            const Target = e.target;

            await this.checked(Target);

            if (Target.name == "group") {
                // Identificar solo la checked del encabezado
                if (Target.checked) {
                    // Solo ejecutar cuando se checkea
                    // Obtener el grupo de cuentas para seleccionar todas las relacionadas
                    let group = getData(Target, "data-group");
                    const G = document.querySelectorAll(
                        `[data-account="${group}"]`
                    );

                    G?.forEach(async (item) => {
                        // Solo seleccionar las que no esten checkeadas
                        if (!item.checked) {
                            item.checked = true;
                            await this.checked(item);
                        }
                    });
                }
            }
        });
    }

    async checked(target) {
        if (target.name == "checked") {
            bodyRequest.set("checked", target.checked);

            let response = await tryAsyncFetch(
                getData(target, "data-url"),
                requestOptions
            );

            if (response.error) {
                target.checked = !target.checked;
                alertHTML(response.error);
            }
        }
    }
}

window.customElements.define("ledger-module", LedgerModule, {
    extends: "section",
});
