import tippy from "tippy.js";
import {
    alertHTML,
    b64Uri,
    defaultSelect2,
    getData,
    getElement,
    getJson,
    setSelect2Ajax,
    readTranslations,
} from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";
import {
    contacts,
    contact,
    remove as removeContact,
    setMain as setMainContact,
} from "./modalContact";

// Variables y Constantes
export let addresses = { addresses: new Array() };
export let address = { address: { id: 0 } };
let currentContacts = [];

/**
 * Eliminar un elemento del array
 */
export const remove = () => {
    const AM = new AddressModal();
    const Address = AM.get();
    const Index = addresses.addresses.findIndex((c) => c.id === Address.id);
    addresses.addresses.splice(Index, 1);

    if (addresses.addresses.length) {
        if (Address.main) {
            addresses.addresses[0].main = Address.main;
        }

        if (Address.billing) {
            addresses.addresses[0].billing = Address.billing;
        }
    }

    addresses.addresses.forEach((item, index) => {
        item.id = index + 1;
    });
};

/**
 * Establecer un elemento como principal
 */
export const setMain = () => {
    addresses.addresses.forEach((c) => (c.main = 0));
    const AM = new AddressModal();
    const Address = AM.get();
    Address.main = 1;
};

/**
 * Establecer un dirección de facturación
 */
export const setBilling = () => {
    addresses.addresses.forEach((c) => (c.billing = 0));
    const AM = new AddressModal();
    const Address = AM.get();
    Address.billing = 1;
};

class AddressModal extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.info(this);
        const FormAddress = getElement("[data-form=formAddress]", this);
        let Address = {};
        let success = false;

        // Start DataTable
        let configDt = {
            ordering: false,
            info: false,
            lengthChange: false,
            stateSave: false,
            pageLength: 5,
            dom: "ftp",
        };

        configDt.columns = [
            { data: "id", with: "3%", class: "text-center" },
            {
                data: "name",
                render: (data, type, row) => {
                    let email = "";
                    let tel = "";

                    if (row.email?.email) {
                        email = `<i class="fa-solid fa-at text-info mx-1 tippy-tooltips" data-tippy-content="${row.email.email}" data-tippy-animation="scale" data-tippy-inertia="true" data-tippy-duration="[300, 100]" data-tippy-arrow="true" role="button"></i>`;
                    }

                    if (row.tel?.tel) {
                        tel = `<i class="fa-solid fa-phone text-success mx-1 tippy-tooltips" data-tippy-content="${row.tel.tel}"  data-tippy-animation="scale" data-tippy-inertia="true" data-tippy-duration="[300, 100]" data-tippy-arrow="true" role="button"></i>`;
                    }

                    let name = data + email + tel;

                    return name;
                },
            },
            {
                data: "main",
                width: "7%",
                class: "text-center",
                render: (data, type, row) => {
                    let checked = "";
                    if (data) {
                        checked = "checked";
                    }
                    let checkBox = `<div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" name="main" value="${row.id}" ${checked}>
                                    </div>`;
                    return checkBox;
                },
            },
            {
                data: "buttons",
                width: "15%",
                class: "text-center",
                render: (data, type, row) => {
                    let buttons = `<i class="fas fa-pencil text-warning me-1" role="button" data-target="edit" data-id="${row.id}"></i>
                                   <i class="fas fa-trash-alt text-danger" role="button" data-target="delete" data-id="${row.id}"></i>`;
                    return buttons;
                },
            },
            // { data: "email", class: "d-none" },
            // { data: "tel", class: "d-none" },
        ];

        configDt.drawCallback = function () {
            tippy(".tippy-tooltips");
        };

        const DtContacts = new Dt(configDt);
        DtContacts.dataTable(getElement("[data-table=dt]", this));

        this.addEventListener("click", async (e) => {
            const CntContacts = e.target.closest("[data-target=cntContacts]");
            const BtnAdd = e.target.closest("[name=add]");
            const BtnCancel = e.target.closest("[name=cancel]");

            // Al cerrar el modal devolver el id de contacto a 0
            if (BtnCancel) {
                address.address.id = 0;

                if (Address && !success) {
                    Address.contacts = currentContacts;
                }
            }

            if (BtnAdd) {
                if (this.validate(FormAddress)) {
                    // Si hay una dirección seleccionada editar sino agregar
                    if (address.address.id) {
                        success = await this.edit(FormAddress);
                    } else {
                        success = await this.add(FormAddress);
                    }

                    // Limpiar los contactos
                    contacts.contacts = [];
                    DtContacts.reloadData(contacts.contacts);

                    if (success) {
                        const BtnCancel = getElement("[name=cancel]", this);
                        BtnCancel.click();
                        success = false;
                    }
                }
            }

            if (CntContacts) {
                const BtnAddContact = e.target.closest("[name=btnAddContact]");
                const Main = e.target.closest("[name=main]");
                const Edit = e.target.closest("[data-target=edit]");
                const Delete = e.target.closest("[data-target=delete]");

                if (BtnAddContact) {
                    contact.contact.id = 0;
                }

                if (Main) {
                    contact.contact.id = getData(Main);
                    setMainContact();
                    contact.contact.id = 0;
                    DtContacts.reloadData(contacts.contacts);
                }

                if (Edit) {
                    const ModalContact = getElement(
                        "[name=btnAddContact]",
                        this
                    );
                    ModalContact.click();
                    contact.contact.id = getData(Edit, "data-id");
                }

                if (Delete) {
                    contact.contact.id = getData(Delete, "data-id");
                    removeContact();
                    contact.contact.id = 0;
                    DtContacts.reloadData(contacts.contacts);
                }
            }
        });

        // Se ejecuta inmediatamente se lanza el modal
        this.addEventListener("show.bs.modal", (e) => {
            // Mostrar los contactos
            DtContacts.reloadData(contacts.contacts);

            if (getData(e.relatedTarget, "name") === "btnAddAddress") {
                // Si el modal fue lanzado para agregar dirección, limpiar sus valores
                FormAddress.reset();
                // Mostrar los contactos
                contacts.contacts = [];
                DtContacts.reloadData(contacts.contacts);
                defaultSelect2(
                    FormAddress.elements.country_id,
                    "españa",
                    "countriesUrlSelect"
                );
            }
        });

        // Esperar que cargue el modal necesario para que contact tenga su valor
        this.addEventListener("shown.bs.modal", (e) => {
            Address = this.get();

            // Si es para editar contacto evitar que se restaure si se lanza desde el modal contacto
            if (Address && getData(e.relatedTarget, "name") !== "cancel") {
                FormAddress.elements.name.value = Address.name;
                FormAddress.elements.address.value = Address.address;
                FormAddress.elements.city.value = Address.city;
                FormAddress.elements.province.value = Address.province;
                FormAddress.elements.zip.value = Address.zip;

                if (Address.country.id) {
                    setSelect2Ajax(
                        FormAddress.elements.country_id,
                        Address.country.id,
                        Address.country.country ?? Address.country.text
                    );
                }

                contacts.contacts = Address.contacts;
                currentContacts = JSON.parse(JSON.stringify(Address.contacts));
                DtContacts.reloadData(contacts.contacts);
            }
        });
    }

    /**
     * Validar los campos del formulario
     *
     * @param {HTMLFormElement} form Elemento de tipo form
     * @returns
     */
    validate(form) {
        let validated = false;

        if (form.reportValidity()) {
            validated = true;
        }

        return validated;
    }

    /**
     * Buscar un item dentro del array de objetos
     *
     * @param {object} item Item objetivo
     * @returns
     */
    search(item) {
        return addresses.addresses.find((c) => c.name === item.name);
    }

    /**
     * Obtener la dirección
     *
     * @returns
     */
    get() {
        return addresses.addresses.find(
            (i) => i.id === parseInt(address.address.id)
        );
    }

    /**
     * Agregar una dirección a la lista
     *
     * @param {HTMLFormElement} form Elemento de tipo form
     * @returns
     */
    async add(form) {
        const Address = {
            id: addresses.addresses.length + 1,
            name: form.elements.name.value,
            address: form.elements.address.value,
            city: form.elements.city.value,
            province: form.elements.province.value,
            zip: form.elements.zip.value,
            contacts: contacts.contacts,
            country: getJson(
                b64Uri(
                    getData(form.elements.country_id, "data-default"),
                    "decode"
                )
            ),
            main: 0,
            billing: 0,
            buttons: null,
        };

        let found = this.search(Address);

        if (found) {
            const Translations = await readTranslations();
            alertHTML(Translations["view.generic.address_exists"], "error");

            return false;
        } else {
            if (!addresses.addresses.length) {
                Address.main = 1;
                Address.billing = 1;
            }

            addresses.addresses.push(Address);
            form.reset();

            return true;
        }
    }

    /**
     * Editar un contacto de la lista
     *
     * @param {HTMLFormElement} form Elemento de tipo form
     */
    async edit(form) {
        const Address = this.get();

        let found = this.search({ name: form.elements.name.value });

        if (found && found.id !== Address.id) {
            const Translations = await readTranslations();
            alertHTML(Translations["view.generic.address_exists"], "error");

            return false;
        } else {
            Address.name = form.elements.name.value;
            Address.address = form.elements.address.value;
            Address.city = form.elements.city.value;
            Address.province = form.elements.province.value;
            Address.zip = form.elements.zip.value;
            Address.contacts = contacts.contacts;
            Address.country = getJson(
                b64Uri(
                    getData(form.elements.country_id, "data-default"),
                    "decode"
                )
            );
            form.reset();

            return true;
        }
    }
}

window.customElements.define("address-modal", AddressModal, {
    extends: "div",
});
