class DashboardModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    load() {
        //
    }
}

window.customElements.define("dashboard-module", DashboardModule, {
    extends: "section",
});
