import {
    Management,
    bodyRequest,
    getData,
    requestOptions,
    tryAsyncFetch,
} from "../helpers";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectStartYear extends HTMLSelectElement {
    connectedCallback() {
        this.load();
        this.addEvents();
    }

    async load() {
        let startYear = localStorage.getItem(Management.slug);

        if (!startYear) {
            localStorage.setItem(Management.slug, this.value);

            startYear = localStorage.getItem(Management.slug);
        }

        this.value = parseInt(startYear);

        await this.setYear(getData(this, "data-url"), parseInt(this.value));
    }

    async addEvents() {
        this.addEventListener("change", async (e) => {
            localStorage.setItem(Management.slug, this.value);
            await this.setYear(
                getData(this, "data-url"),
                parseInt(this.value),
                e
            );
        });
    }

    async setYear(url, year, e = null) {
        bodyRequest.set("year", year);
        let setY = await tryAsyncFetch(url, requestOptions);
        setY.event = e?.type;

        this.dispatchEvent(
            new CustomEvent("aj:startYear", {
                detail: setY,
                bubbles: true,
                cancelable: true,
            })
        );
    }

    reset() {
        // this.dataS.notIn = [];
    }
}

window.customElements.define("select-startyear", SelectStartYear, {
    extends: "select",
});
