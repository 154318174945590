import { SelectDos } from "../plugins/select2";
import {
    getData,
    CSRF,
    defaultSelect2,
    getJson,
    b64Uri,
    setSelect2Ajax,
} from "../helpers";
// import ucwords from "ucwords";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectEmployee extends HTMLSelectElement {
    connectedCallback() {
        let configSelect = {
            ajax: {
                url: getData("employeesUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    return {
                        _token: CSRF,
                        search: param.term,
                        // limit: 10,
                    };
                },
                processResults: function (response) {
                    // response.forEach((element) => {
                    //     element.id = element.alpha2;
                    // });
                    return {
                        results: response,
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        SD.selectDos(this);

        if (getData(this, "data-default")) {
            let Data = getJson(b64Uri(getData(this, "data-default"), "decode"));
            if (Data?.id) {
                setSelect2Ajax(this, Data.id, `${Data.text}`);
            }
        }
    }

    reset() {
        // this.dataS.notIn = [];
    }
}

window.customElements.define("select-employee", SelectEmployee, {
    extends: "select",
});
