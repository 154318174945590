import { SelectDos } from "../plugins/select2";
import { getData, CSRF } from "../helpers";
// import ucwords from "ucwords";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectAccountingGroups extends HTMLSelectElement {
    connectedCallback() {
        let configSelect = {
            ajax: {
                url: getData("accountinggroupsUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    return {
                        _token: CSRF,
                        search: param.term
                    };
                },
                processResults: function (response) {
                    return {
                        results: response,
                    };
                },
            },
        };

        const SD = new SelectDos(configSelect);
        SD.selectDos(this);
    }

    reset() {
        // this.dataS.notIn = [];
    }
}

window.customElements.define("select-accountinggroup", SelectAccountingGroups, {
    extends: "select",
});
