import { SelectDos } from "../plugins/select2";
import { getData, CSRF, getJson, b64Uri, setSelect2Ajax } from "../helpers";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectProject extends HTMLSelectElement {
    dataS = { notIn: [] };

    async connectedCallback() {
        let self = this;
        let configSelect = {
            ajax: {
                url: getData("projectsUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (params) {
                    // Simular scroll infinito
                    let page = params.page || 1;
                    return {
                        _token: CSRF,
                        search: params.term,
                        page: page,
                        notIn: self.dataS.notIn,
                    };
                },
                processResults: function (response, params) {
                    // Simular scroll infinito
                    params.page = params.page || 1;
                    let more =
                        params.page * response.limit < response.total_count;
                    return {
                        results: response.items,
                        pagination: {
                            more: more,
                        },
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        const S = await SD.selectDos(this);

        S[0]?.addEventListener("aj:select2", (e) => {
            const Data = e.detail.data;

            this.filter(Data);
        });

        S[0]?.addEventListener("aj:change", (e) => {
            const Data = e.detail.data;

            this.filter(Data);
        });

        S[0]?.addEventListener("aj:unselecting", (e) => {
            const Data = e.detail.data;

            this.filter(Data, false);
        });

        if (getData(this, "data-default")) {
            let Data = getJson(b64Uri(getData(this, "data-default"), "decode"));

            if (this.hasAttribute("multiple")) {
                Data?.forEach((data) => {
                    if (data?.id) {
                        setSelect2Ajax(
                            this,
                            data.id,
                            data.name ?? data.text,
                            data
                        );

                        this.filter(data);
                    }
                });
            } else {
                if (Data?.id) {
                    setSelect2Ajax(this, Data.id, Data.name ?? Data.text, Data);

                    this.filter(Data);
                }
            }
        }
    }

    filter(data, add = true) {
        if (!this.hasAttribute("data-not-filter")) {
            if (data?.id) {
                if (add) {
                    if (this.hasAttribute("multiple")) {
                        this.dataS.notIn.push(data.id);
                    } else {
                        this.dataS.notIn = [data.id];
                    }
                } else {
                    this.dataS.notIn = this.dataS.notIn.filter(
                        (item) => item != data.id
                    );
                }
            }
        }
    }

    reset() {
        this.dataS.notIn = [];
    }
}

window.customElements.define("select-project", SelectProject, {
    extends: "select",
});
